/* src/components/MathTool.css */
@import './Chat.css';


.chat-container {
    display: flex;
    flex-direction: column;
    width: 67%;
    height: 100%;
    background-color: transparent;
    border-radius: 12px;
    overflow: hidden;
    transition: width 0.3s ease, background-color 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .chat-container {
        width: 80%;
    }
}

@media (max-width: 480px) {
    .chat-container {
        width: 95%;
        border-radius: 0;
    }
}

/* Styles for the chat-content */
.chat-content {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.chat-content.centered {
    justify-content: center; /* Vertically center the content */
    align-items: center; /* Horizontally center the content */
}

.instruction {
    text-align: center;
    margin-bottom: 10px;
    background-color: transparent; /* No background */
}

.instruction h2 {
    margin: 0;
    font-size: 24px;
    color: var(--color-primary-text);
}

.instruction p {
    margin: 5px 0 0 0;
    font-size: 16px;
    color: var(--color-secondary-text);
}

/* Rest of your styles remain unchanged */
