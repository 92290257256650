/* src/components/StudyGuide.css */
@import './Chat.css';

.feature-section {
    background-color: var(--color-background);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.feature-section h2 {
    font-size: 2em;
    color: var(--color-primary-text);
    margin-bottom: 20px;
}

.feature-section p {
    font-size: 1em;
    color: var(--color-secondary-text);
}
