/* src/App.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600&display=swap');

/* --------------------------------------------------
   CSS Variables for Light Mode Palette
-------------------------------------------------- */
:root {
    /* Primary Colors */
    --color-background: #FFFFFF; /* White */
    --color-primary-text: #333333; /* Dark Gray */
    --color-secondary-text: #666666; /* Medium Gray */
    --color-accent: #5E99BD; /* Blue Accent */
    --color-border: #DDDDDD; /* Light Gray */
    --color-highlight: #FFC93C; /* Amber */

    /* Additional Colors */
    --color-button-hover: #4A7CA0; /* Darker Blue for hover */
    --color-link-hover: #4A7CA0; /* Darker Blue for link hover */
    --color-copy-feedback: #4caf50; /* Green for copy feedback */
    --color-section-background: #F7F7F7; /* Light Gray for sections */
    --color-ribbon-background: #5E99BD; /* Blue for ribbons */
    --color-ribbon-second: linear-gradient(to bottom right, #fff5cb, #84bccf); /* Gradient for second ribbon */
}

/* Dark Mode Variables */
.dark-mode {
    --color-background: #1e1e1e; /* Dark background */
    --color-primary-text: #e0e0e0; /* Light Gray text */
    --color-secondary-text: #b0b0b0; /* Medium Gray */
    --color-accent: #5E99BD; /* Blue Accent remains same or adjust as needed */
    --color-border: #333333; /* Dark Gray border */
    --color-highlight: #FFC93C; /* Amber */
    --color-button-hover: #4A7CA0; /* Darker Blue for hover */
    --color-link-hover: #4A7CA0; /* Darker Blue for link hover */
    --color-copy-feedback: #4caf50; /* Green for copy feedback */
    --color-section-background: #2c2c2c; /* Dark Gray for sections */
    --color-ribbon-background: #5E99BD; /* Blue for ribbons */
    --color-ribbon-second: linear-gradient(to bottom right, #fff5cb, #84bccf); /* Gradient for second ribbon */
}

/* --------------------------------------------------
   Global Styles
-------------------------------------------------- */
/* Apply box-sizing globally for better control */
*, *::before, *::after {
    box-sizing: border-box;
}

/* Reset default margins and paddings */
html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    background-color: var(--color-background);
    color: var(--color-primary-text);
    font-family: 'Rubik', sans-serif;
}

/* Ensure all text elements use Rubik font */
body, h1, h2, h3, h4, h5, h6, p, a, span, div, button {
    font-family: 'Rubik', sans-serif;
}

/* App Container */
.app-container {
    display: flex;
    height: 100vh;
    overflow: hidden;
    background-color: var(--color-background);
    transition: background-color 0.3s ease, color 0.3s ease;
}

/* Dark Mode Adjustments */

.dark-mode .sidebar {
    background-color: var(--color-section-background);
    border-right: 1px solid var(--color-border);
}

.dark-mode .sidebar-title {
    color: var(--color-primary-text);
}

.dark-mode .sidebar-tab {
    color: var(--color-primary-text);
}

.dark-mode .sidebar-tab.active,
.dark-mode .sidebar-tab:hover {
    background-color: var(--color-accent);
    color: #fff;
}

.dark-mode .dark-mode-toggle {
    background-color: var(--color-accent);
    color: #fff;
}

.dark-mode .dark-mode-toggle:hover {
    background-color: var(--color-button-hover);
}

.dark-mode .chat-container {
    background-color: transparent; /* Ensure transparency in dark mode */
}

.dark-mode .chat-window {
    background-color: transparent;
}

.dark-mode .message.bot {
    background-color: #444444;
    color: var(--color-primary-text);
}

.dark-mode .message.user {
    background-color: var(--color-accent);
    color: #fff;
}

.dark-mode .chat-input-area {
    background-color: var(--color-section-background);
    border-top: 1px solid var(--color-border);
}

.dark-mode .chat-input {
    background-color: #333333;
    color: var(--color-primary-text);
    border: 1px solid var(--color-border);
}

.dark-mode .chat-input:focus {
    border-color: var(--color-accent);
    background-color: #444444;
}

.dark-mode .send-button {
    background-color: var(--color-accent);
    color: #fff;
}

.dark-mode .send-button:hover {
    background-color: var(--color-button-hover);
}

.dark-mode .feature-section {
    background-color: var(--color-background);
    color: var(--color-primary-text);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
}
