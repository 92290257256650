/* src/components/Sidebar.css */

.sidebar {
    width: 250px;
    background-color: var(--color-section-background);
    border-right: 1px solid var(--color-border);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh; /* Full viewport height */
    box-sizing: border-box;
    position: fixed; /* Fixed position */
    left: 0;
    top: 0;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

.sidebar-title {
    font-size: 1.8em;
    font-weight: 600;
    margin-bottom: 30px;
    color: var(--color-primary-text);
}

.sidebar-tabs {
    list-style: none;
    padding: 0;
    width: 100%;
}

.sidebar-tab {
    padding: 12px 20px;
    margin-bottom: 10px;
    border-radius: 8px;
    cursor: pointer;
    color: var(--color-primary-text);
    transition: background-color 0.3s ease, color 0.3s ease;
}

.sidebar-tab:hover {
    background-color: var(--color-accent);
    color: #fff;
}

.sidebar-tab.active {
    background-color: var(--color-accent);
    color: #fff;
    font-weight: 500;
}

.dark-mode-toggle {
    margin-top: auto; /* Push to the bottom */
    padding: 10px 20px;
    background-color: var(--color-accent);
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 1em;
    font-weight: 500;
}

.dark-mode-toggle:hover {
    background-color: var(--color-button-hover);
}
