/* src/components/Coding.css */
@import './Chat.css';

.coding-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .instruction-card {
    text-align: center;
    padding: 20px;
    background-color: #f8f8f8; /* Light background for the card */
    border-radius: 8px;
    margin: 20px;
  }
  
  .instruction-card h2 {
    margin-bottom: 10px;
  }
  
  .instruction-card p {
    margin: 0;
  }
  
  .chat-window {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
  }
  
  /* Rest of your styles */
  