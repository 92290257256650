/* src/components/EssayWriting.css */
@import './Chat.css';

/* Use the same .chat-container styles as in Chat.css */
.chat-container {
    display: flex;
    flex-direction: column;
    width: 67%; /* Same width as Chat module */
    height: 100%; /* Use full available height */
    background-color: transparent;
    border-radius: 12px;
    box-shadow: none;
    overflow: hidden;
    transition: width 0.3s ease, background-color 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .chat-container {
        width: 80%; /* Increase width on smaller screens */
    }
}

@media (max-width: 480px) {
    .chat-container {
        width: 95%; /* Further increase width on very small screens */
        border-radius: 0;
    }
}

/* Instruction styling */
.instruction {
    text-align: center;
    margin-bottom: 10px;
    background-color: transparent; /* No background */
}

.instruction h2 {
    margin: 0;
    font-size: 24px;
}

.instruction p {
    margin: 5px 0 0 0;
    font-size: 16px;
}

/* Ensure .chat-window and .chat-input-area fill the space correctly */
.chat-window {
    flex: 1; /* Allow chat window to expand */
    overflow-y: auto;
    padding: 20px;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

/* Use the same styles for the chat input area */
.chat-input-area {
    display: flex;
    justify-content: center;
    padding: 15px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.chat-input {
    flex: 1;
    padding: 12px 16px;
    border: none;
    border-radius: 25px;
    font-size: 1em;
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
    color: var(--color-primary-text);
    margin-right: 10px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.chat-input::placeholder {
    color: var(--color-secondary-text);
}

.chat-input:focus {
    background-color: rgba(0, 0, 0, 0.1);
}

.send-button {
    padding: 12px 20px;
    background-color: var(--color-accent);
    color: #fff;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    font-size: 1em;
    font-weight: 500;
}

.send-button:hover {
    background-color: var(--color-button-hover);
    transform: translateY(-2px);
}

.send-button:active {
    transform: translateY(0);
}
