/* src/components/MainContent.css */

.main-content {
    flex: 1;
    display: flex;
    justify-content: center; /* Centers the chat container horizontally */
    align-items: flex-start; /* Aligns chat container to the top */
    padding: 20px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .main-content {
        padding: 15px;
    }
}

@media (max-width: 480px) {
    .main-content {
        padding: 10px;
    }
}
