/* src/components/Message.css */

.message {
  max-width: 80%;
  padding: 12px 16px;
  border-radius: 20px;
  position: relative;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.3s forwards;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.message.user {
  align-self: flex-end;
  background-color: var(--color-accent);
  color: #fff;
  border-bottom-right-radius: 0;
}

.message.bot {
  align-self: flex-start;
  background-color: #f0f0f0;
  color: var(--color-primary-text);
  border-bottom-left-radius: 0;
}

.message.bot::after {
  content: '';
  position: absolute;
  top: 10px;
  left: -20px; /* Increased from -10px to -20px */
  border-width: 10px 10px 10px 0;
  border-style: solid;
  border-color: transparent var(--color-accent) transparent transparent;
}

.message.user::after {
  content: '';
  position: absolute;
  top: 10px;
  right: -20px; /* Increased from -10px to -20px */
  border-width: 10px 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent transparent var(--color-accent);
}

.message-content {
  font-size: 1em;
  line-height: 1.4;
}

.message-timestamp {
  font-size: 0.75em;
  color: var(--color-secondary-text);
  margin-top: 5px;
  align-self: flex-end;
}

/* Remove duplicate styles if present */
