/* src/components/ChatWindow.css */

.chat-window {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    background-color: transparent; /* Transparent background */
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    transition: background-color 0.3s ease;
}

.chat-window::-webkit-scrollbar {
    width: 8px;
}

.chat-window::-webkit-scrollbar-track {
    background: var(--color-section-background);
    border-radius: 4px;
}

.chat-window::-webkit-scrollbar-thumb {
    background-color: var(--color-accent);
    border-radius: 4px;
}

.message {
    max-width: 70%;
    padding: 12px 16px;
    border-radius: 20px;
    position: relative;
    word-wrap: break-word;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn 0.3s forwards;
}

.message.user {
    align-self: flex-end;
    background-color: var(--color-accent);
    color: #fff;
    border-bottom-right-radius: 0;
}

.message.bot {
    align-self: flex-start;
    background-color: #f0f0f0;
    color: var(--color-primary-text);
    border-bottom-left-radius: 0;
}

.message.bot::after {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    border-width: 10px 10px 10px 0;
    border-style: solid;
    border-color: transparent var(--color-accent) transparent transparent;
}

.message.user::after {
    content: '';
    position: absolute;
    top: 10px;
    right: -10px;
    border-width: 10px 0 10px 10px;
    border-style: solid;
    border-color: transparent transparent transparent var(--color-accent);
}

.message-content {
    font-size: 1em;
    line-height: 1.4;
}

.message-timestamp {
    font-size: 0.75em;
    color: var(--color-secondary-text);
    margin-top: 5px;
    align-self: flex-end;
}

.loading-message {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px; /* Adjust as needed */
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
